.courses {
	.courses__list {
		padding-top: 32px;
		padding-right: 10px;
		ul {
			display: flex;
			flex-direction: column;
			row-gap: 26px;
			.list__item {
				display: flex;
				align-items: center;
				.course__icon {
					align-self: flex-start;
					margin-right: 16px;
				}
				.course__info {
					margin-right: 16px;
					flex-grow: 1;
					h4 {
						font-weight: 800;
						font-size: 16px;
						color: #121212;
						margin-bottom: 8px;
					}
					p {
						font-weight: 400;
						font-size: 14px;
						line-height: 16px;
						color: #535353;
					}
				}
				.course__link {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25px;
					height: 25px;
					border: 1px solid black;
					flex-shrink: 0;
				}
				&:not(:last-child) {
					margin-bottom: 22px;
				}
			}
		}
	}
}
