.team-slides {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: #ffd873;
  .slider__content {
    display: flex;
    position: relative;
    .swiper {
      margin: 0 32px;
      .swiper-wrapper {
        .swiper-slide {
          .slider__item {
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            .slider__img {
              width: 100%;
              aspect-ratio: 1/1;
              margin-right: 16px;
              margin-bottom: 32px;
              transform: translate(-16px, -16px);
              box-shadow: 16px 16px 0 0 white, 16px 16px 0 3px black;
              transition: 0.3s;
              img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                filter: grayscale(1);
                border: 3px solid black;
                transition: 0.3s;
              }
              &:hover {
                box-shadow: -16px -16px 0 0 white, -16px -16px 0 3px black;
                transform: translate(4px, 4px);
                img {
                  filter: grayscale(0);
                }
              }
            }

            .slider__info {
              h4 {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 24px;
                line-height: 26px;
                letter-spacing: -0.02em;
                color: #121212;
                margin-bottom: 16px;
              }
              p {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
              }
            }
          }
        }
      }
    }
    .swiper-next,
    .swiper-prev {
      position: absolute;
      top: 135px;
      z-index: 999;
      cursor: pointer;
    }
    .swiper-next {
      right: 0px;
      &:after {
        content: '';
      }
    }
    .swiper-prev {
      left: 0;
      &:after {
        content: '';
      }
    }
  }
}
