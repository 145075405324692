.address {
	background-color: #ffd873;
	.address__heading {
		margin-bottom: 60px;
	}
	.address__info {
		width: 100%;
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
		.address__info-list {
			width: 100%;
			display: flex;
			flex-direction: column;
			row-gap: 25px;
			.address__info-list__item {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				column-gap: 10px;
				padding: 10px 20px;
				&:first-child {
					.address__info__txt {
						grid-column: 1/3;
					}
				}
			}
			.address__info__heading {
				display: flex;
				justify-content: space-between;
			}
			.address__info__title {
				display: block;
				margin-bottom: 15px;
				font-size: 16px;
				color: #006da7;
			}
			.address__info__link {
				display: flex;
				align-items: center;
				column-gap: 5px;
				flex-grow: 1;
				font-size: 16px;
				text-decoration-line: underline;
				color: #121212;
				justify-self: end;
			}
			.address__info__txt {
				font-size: 14px;
				color: #121212;
				font-style: normal;
			}
		}
		.address__img {
			display: none;
		}
	}
}
