body {
	background-color: #f5f5f5;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.wrapper {
	max-width: 640px;
	margin: 0 auto;
	overflow: hidden;
}

.container {
	padding-right: 25px;
	padding-left: 25px;
}

ul {
	list-style: none;
}

section {
	padding: 60px 0;
	.title {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 26px;
		letter-spacing: -0.05em;
		color: #121212;
		margin-bottom: 16px;
	}
	.descr {
		width: 80%;
		font-size: 14px;
		line-height: 19px;
		color: #121212;
	}
}

main {
	background-color: white;
}

@import '../../styles/media';
