.module {
	border-bottom: 1px dashed #767676;
	padding-bottom: 32px;
	.module__list {
		&:nth-of-type(4n + 1) {
			.module__list__heading {
				background: #ffd873;
				color: #121212;
			}
		}
		&:nth-of-type(4n + 2) {
			.module__list__heading {
				background: #006bcc;
			}
		}
		&:nth-of-type(4n + 3) {
			.module__list__heading {
				background: #007c54;
			}
		}
		&:nth-of-type(4n + 4) {
			.module__list__heading {
				background: #fb5009;
			}
		}
		&:not(:last-child) {
			margin-bottom: 32px;
		}
		&__heading {
			display: flex;
			justify-content: space-between;
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			color: white;
			align-items: center;
			background: #ffd873;
			padding: 16px;
			border-bottom: 1px solid #000000;
			margin-bottom: 16px;
			cursor: pointer;
			.arrow {
				transition: 0.1s;
				svg {
					width: 18px;
				}
			}
			&.active {
				.arrow {
					transform: rotate(90deg);
				}
			}
			&.active ~ .module__list__item {
				display: flex;
			}
		}
		&__item {
			display: none;
			align-items: center;
			column-gap: 16px;
			padding: 6px 16px;
			background: #f5f5f5;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			.list__numb {
				font-size: 14px;
				letter-spacing: -0.05em;
				color: #000000;
				padding: 6px 16px 6px 0;
				border-right: 1px solid #c9c9c9;
				line-height: 1;
			}
			.list__text {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #535353;
			}
		}
	}
}
.course__price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 32px;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: -0.05em;
	color: #000000;
	.monthly {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 12px;
	}
	.total__price {
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 5px;
	}
}
