footer {
	background-color: #121212;
	.footer__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.soc__media {
			display: flex;
			justify-content: flex-end;
			column-gap: 25px;
			margin-bottom: 8px;
		}
		.contact {
			display: flex;
			column-gap: 16px;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: -0.02em;
			.tel {
				color: white;
			}
		}
	}
}
