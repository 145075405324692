header {
	position: relative;
	background-color: #2660b1;
	.container {
		display: flex;
		justify-content: space-between;
		padding-top: 25px;
		padding-bottom: 25px;
		nav {
			width: 100%;
			padding: 32px 16px;
			position: absolute;
			left: -100%;
			top: 75px;
			background-color: rgba(255, 255, 255, 0.8);
			backdrop-filter: blur(5px);
			z-index: 99;
			box-shadow: 0 5px 100px 0px rgba(0, 0, 0, 0.1);
			transition: 0.3s;
			&.active {
				left: 0;
			}
			ul {
				margin-bottom: 40px;
				li {
					cursor: pointer;
					a {
						font-weight: 900;
						font-size: 14px;
						line-height: 36px;
						color: #000000;
						transition: 0.3s;
						&:hover {
							color: white;
						}
						&.active {
							color: #006bcc;
						}
					}
				}
			}
			.header__contact {
				display: flex;
				align-items: center;
				column-gap: 8px;
				.text {
					font-size: 12px;
					color: #006bcc;
				}
				.number {
					font-size: 14px;
					font-weight: 900;
					color: #000000;
					user-select: all;
				}
			}
		}
		.header__left {
			display: flex;
			align-items: center;
			a {
				font-weight: 900;
				font-size: 14px;
				color: #ffffff;
				margin-right: 20px;
				text-decoration: none;
			}
			.header__menu {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				width: 35px;
				height: 30px;
				cursor: pointer;
				&:before,
				&:after,
				div {
					transition-duration: 0.3s;
					width: 100%;
					height: 3px;
					background-color: #fff;
				}
				&:before,
				&:after {
					content: '';
					display: block;
				}
				&.active {
					justify-content: center;
					align-items: center;
					div {
						width: 0;
					}
					&:before,
					&:after {
						position: absolute;
					}
					&:before {
						transform: rotate(45deg);
					}
					&:after {
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}
