.partners {
	&__heading {
		margin-bottom: 16px;
	}
	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 36px;
		&__item {
			display: flex;
			height: 96px;
			justify-content: center;
			align-items: center;
			border-top: 1px solid #e7e7e7;
			&:last-child {
				border-bottom: 1px solid #e7e7e7;
			}
			&:nth-last-child(2) {
				border-bottom: 1px solid #e7e7e7;
			}
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					max-width: 80%;
				}
			}
		}
	}
}
