.loader {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	z-index: 99999999;
	&__wrapper {
		.loader__items {
			display: flex;
			margin-bottom: 10px;
			.loader__item {
				position: relative;
				display: flex;
				justify-content: center;
				width: 25px;
				height: 25px;
				background-color: #2a6bc5;
				border-radius: 50%;
				animation: loader 0.5s alternate infinite;
				&:not(:last-child) {
					margin-right: 15px;
				}
				&:nth-child(2) {
					animation-delay: 0.1s;
					background-color: #ffd873;
				}
				&:nth-child(3) {
					animation-delay: 0.2s;
					background-color: #337a57;
				}
				&:nth-child(4) {
					animation-delay: 0.3s;
					background-color: #ea5d2c;
				}
			}
		}
		.loader__shadows {
			display: flex;
			.loader__shadow {
				position: relative;
				display: flex;
				justify-content: center;
				width: 25px;
				height: 25px;
				&::before {
					content: '';
					position: absolute;
					width: 80%;
					height: 12px;
					border-radius: 100%;
					background-color: rgba(0, 0, 0, 0.03);
					filter: blur(2px);
					animation: loaderShadow 0.5s alternate infinite;
				}
				&:nth-child(2)::before {
					animation-delay: 0.1s;
				}
				&:nth-child(3)::before {
					animation-delay: 0.2s;
				}
				&:nth-child(4)::before {
					animation-delay: 0.3s;
				}
				&:not(:last-child) {
					margin-right: 15px;
				}
			}
		}
	}
}

@keyframes loader {
	from {
		transform: scaleX(1.25);
	}

	to {
		transform: translateY(-50px) scaleY(1);
	}
}

@keyframes loaderShadow {
	from {
		width: 0;
		height: 0;
	}

	to {
		width: 100%;
	}
}
