section.contact {
	background-image: url('../../resources/images/contact-bg.png'),
		linear-gradient(180deg, rgba(255, 216, 115, 0) 0%, #ffd873 100%);
	background-position: bottom center;
	background-repeat: no-repeat;
	background-color: #ffd873;
	background-size: auto;
	padding-bottom: 120px;
	.contact__heading {
		margin-bottom: 32px;
	}
	form {
		display: flex;
		flex-direction: column;
		input {
			font-family: 'Nunito Sans';
			padding: 20px 17px;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #b5b5b5;
			background-color: #fff;
			border: 0;
			border-bottom: 1px solid #535353;
			outline: 0;
			&.error {
				animation-name: inpErrorAnimation;
				animation-duration: 200ms;
				animation-iteration-count: 1;
			}
			&:first-child {
				margin-bottom: 10px;
			}
		}
		button {
			padding: 20px;
			background: #121212;
			border: 2px solid #121212;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: #ffffff;
			margin-top: 32px;
			cursor: pointer;
			&.disabled {
				opacity: 0.6;
				cursor: not-allowed;
			}
		}
	}
}

@keyframes inpErrorAnimation {
	0% {
		transform: translateX(0);
	}
	33.3% {
		transform: translateX(-20px);
	}
	66.6% {
		transform: translateX(20px);
	}
	100% {
		transform: translateX(0px);
	}
}
