.promo {
	width: 100%;
	padding-top: 64px;
	padding-bottom: 64px;
	background: url('../../resources/images/owa-pattern.svg') top left/auto repeat
		#2a6bc5;
	.promo__content {
		max-width: 450px;
		margin-bottom: 42px;
		.promo__right {
			display: none;
		}
		.promo__left {
			h1 {
				font-weight: 700;
				font-size: 32px;
				line-height: 39px;
				letter-spacing: -0.04em;
				color: #ffffff;
				margin-bottom: 30px;
				span {
					color: #f5b53f;
					text-decoration: underline;
					text-underline-offset: 5px;
				}
			}
			p {
				font-size: 18px;
				line-height: 25px;
				color: #ffffff;
				margin-bottom: 30px;
			}
			button {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.01em;
				color: #000000;
			}
		}
	}
}
