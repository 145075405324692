.promo__info {
	display: flex;
	justify-content: space-around;
	.info__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: max-content;
		.icon {
			min-width: 40px;
			margin-bottom: 5px;
			filter: drop-shadow(3px 3px 0 #0043a0);
		}
		.info__title {
			font-weight: 600;
			font-size: 10px;
			color: #ffffff;
			margin-bottom: 5px;
		}
		.info__descr {
			width: max-content;
			font-weight: 800;
			font-size: 12px;
			color: #ffffff;
		}
	}
}
