.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #337a57;
  .testimonials__heading {
    margin-bottom: 16px;
    .title {
      color: white;
    }
    .descr {
      color: white;
    }
  }
  .testimonials__slider {
    display: flex;
    position: relative;
    width: 100%;
    .swiper {
      margin: 0;
    }
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      pointer-events: all;
      opacity: 1;
    }
    .slider__wrapper {
      .slider__card {
        padding: 20px;
        background-color: white;
        border: 4px solid #000000;
        .card__header {
          display: flex;
          align-items: center;
          column-gap: 25px;
          margin-bottom: 40px;
          .card__img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            object-position: center center;
            img {
              max-width: 100%;
            }
          }

          .card__info {
            .company {
              font-weight: 400;
              font-size: 12px;
              color: #535353;
              margin-bottom: 5px;
            }
            .student-name {
              font-weight: 700;
              font-size: 16px;
              color: #121212;
            }
          }
        }
        .card__body {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          .info__list {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .list__title {
              min-width: 40%;
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: -0.05em;
              color: #000000;
            }
            .list__descr {
              width: max-content;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              column-gap: 10px;
              font-family: 'Nunito Sans';
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              color: #121212;
              span {
                line-height: 0;
              }
            }
          }
        }
      }
    }
    .swiper-next,
    .swiper-prev {
      display: none;
      position: absolute;
      top: 50%;
      z-index: 999;
      cursor: pointer;
    }
    .swiper-next {
      right: 0;
      &:after {
        content: '';
      }
    }
    .swiper-prev {
      left: 0;
      &:after {
        content: '';
      }
    }
  }
}
