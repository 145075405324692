.team {
	.team__list {
		padding-top: 32px;
		padding-right: 10px;
		.list__item {
			display: flex;
			&:not(:last-child) {
				margin-bottom: 32px;
			}
			.item__img {
				width: 65px;
				height: 65px;
				margin-right: 16px;
				img {
					max-width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: top center;
				}
			}
			.item__info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				p {
					font-weight: 600;
					font-size: 14px;
					color: #000000;
					margin-bottom: 5px;
				}
				h4 {
					font-weight: 700;
					font-size: 16px;
					letter-spacing: -0.02em;
					color: #121212;
				}
			}
		}
	}
}
