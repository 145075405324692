@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&family=Nunito+Sans:wght@300;400;500;600;700;900&display=swap');

html {
  scroll-behavior: smooth;
  user-select: none;
}

body {
  font-family: 'Nunito Sans', sans-serif;
}
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0;
}

@import 'styles/buttons';
