.btn {
	display: block;
	padding: 16px 24px;
	background: #ffffff;
	border: 2px solid #000000;
	cursor: pointer;
	transition: 0.3s;
	&:nth-of-type(4n + 1) {
		box-shadow: 10px 10px 0 0 #f5b53f, 10px 10px 0 2px black;
	}
	&:nth-of-type(4n + 2) {
		box-shadow: 10px 10px 0 0 #337a57, 10px 10px 0 2px black;
	}
	&:nth-of-type(4n + 3) {
		box-shadow: 10px 10px 0 0 #fb5009, 10px 10px 0 2px black;
	}
	&:nth-of-type(4n + 4) {
		box-shadow: 10px 10px 0 0 #006bcc, 10px 10px 0 2px black;
	}
	&:hover {
		transform: translate(10px, 10px);
		&:nth-of-type(4n + 1) {
			box-shadow: -10px -10px 0 0 #f5b53f, -10px -10px 0 2px black;
		}
		&:nth-of-type(4n + 2) {
			box-shadow: -10px -10px 0 0 #337a57, -10px -10px 0 2px black;
		}
		&:nth-of-type(4n + 3) {
			box-shadow: -10px -10px 0 0 #fb5009, -10px -10px 0 2px black;
		}
		&:nth-of-type(4n + 4) {
			box-shadow: -10px -10px 0 0 #006bcc, -10px -10px 0 2px black;
		}
	}
	&:active {
		transform: translate(0, 0);
		&:nth-of-type(4n + 1) {
			box-shadow: 0 0 0 0 #f5b53f;
		}
		&:nth-of-type(4n + 2) {
			box-shadow: 0 0 0 0 #337a57;
		}
		&:nth-of-type(4n + 3) {
			box-shadow: 0 0 0 0 #fb5009;
		}
		&:nth-of-type(4n + 4) {
			box-shadow: 0 0 0 0 #006bcc;
		}
	}
}
