@media only screen and (max-width: 370px) {
  section {
    .descr {
      width: 100%;
    }
  }
  footer {
    .footer__content {
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      .soc__media {
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: 640px), (max-width: 640px) {
  .wrapper {
    max-width: 100%;
  }
  .address {
    .address__info {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .promo {
    .promo__content {
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;
      .promo__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 48px;
        h1,
        p {
          text-align: center;
          margin: 0;
        }
        h1 {
          font-size: 56px;
          line-height: 68px;
          letter-spacing: -0.04em;
        }
        p {
          font-size: 24px;
          line-height: 32px;
          color: rgba(255, 255, 255, 0.72);
        }
        button.btn {
          box-shadow: 0px 0px 0 10px #f5b53f, 0px 0px 0 0px white;
          transform: translate(0, 0);
        }
      }
    }
    .promo__info {
      .info__item {
        .icon {
          svg {
            width: 56px;
            height: 56px;
          }
        }
        .info__title {
          font-size: 18px;
        }
        .info__descr {
          font-size: 16px;
        }
      }
    }
  }
  .courses {
    .courses__list {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
      }
    }
  }
  .team {
    .team__list {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
      }
    }
  }
}

@media only screen and (min-width: 850px) {
  .address {
    padding-right: 24px;
    .address__info {
      .address__img {
        display: block;
        width: 100%;
        box-shadow: 20px 20px 0 0 #ffffff, 20px 20px 0 3px #000;
        img {
          display: block;
          max-width: 100%;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .testimonials {
    .testimonials__slider {
      width: 100%;
      .swiper {
        padding-left: 20px;
        margin: 0 45px 0 25px;
      }
      .slider__wrapper {
        padding-bottom: 20px;
        .slider__card {
          padding: 20px;
          box-shadow: 14px 12px 0 0 #2a6bc5, 14px 12px 0 4px #000000;
        }
      }
      .swiper-next,
      .swiper-prev {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  section {
    padding: 120px 0;
    .title {
      font-size: 40px;
      margin-bottom: 24px;
    }
    .descr {
      width: 70%;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 56px;
    }
  }
  header {
    .container {
      display: flex;
      nav {
        width: max-content;
        display: flex;
        column-gap: 65px;
        position: static;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        ul {
          display: flex;
          column-gap: 32px;
          margin: 0;
          li {
            a {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.4);
              &.active {
                color: white;
              }
            }
          }
        }
        .header__contact {
          display: flex;
          align-items: center;
          column-gap: 8px;
          .header__contact__icon {
            display: none;
          }
          .text {
            display: none;
          }
          .number {
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
          }
        }
      }

      .header__left {
        display: none;
      }
    }
  }
  .promo {
    width: 100%;
    .promo__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 32px;
      max-width: 100%;
      margin-bottom: 100px;
      padding-right: 50px;
      .promo__left {
        display: flex;
        flex-direction: column;
        row-gap: 48px;
        h1,
        p {
          margin: 0;
        }
        h1 {
          font-size: 56px;
          line-height: 68px;
          letter-spacing: -0.04em;
        }
        p {
          font-size: 24px;
          line-height: 32px;
          color: rgba(255, 255, 255, 0.72);
        }
      }
      .promo__right {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../resources/images/video-bg.webp');
        background-position: center center;
        background-size: cover;
        background-color: #2a6bc5;
        border: 3px solid #121212;
        aspect-ratio: 566/376;
        box-shadow: 20px 20px 0 0 white, 20px 20px 0 3px black;
        svg {
          opacity: 0.85;
        }
      }
    }
    .promo__info {
      .info__item {
        .icon {
          svg {
            width: 56px;
            height: 56px;
          }
        }
        .info__title {
          font-size: 18px;
        }
        .info__descr {
          font-size: 16px;
        }
      }
    }
  }
  .courses {
    .courses__list {
      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 46px;
        row-gap: 40px;
        .btn {
          padding: 40px;
          box-shadow: none;
          background: #f5f5f5;
          border: 0;
          .list__item {
            flex-direction: column;
            align-items: flex-start;
            .course__icon {
              padding: 20px 16px;
              border: 1px solid #eaeaea;
              margin-bottom: 40px;
              svg {
                width: 40px;
                height: 32px;
              }
            }
            .course__info {
              margin-bottom: 32px;
              h4 {
                font-weight: 700;
                font-size: 28px;
                color: #121212;
              }
              p {
                font-size: 16px;
                line-height: 24px;
              }
            }
            .course__link {
              width: 56px;
              height: 56px;
              background: #eaeaea;
              border: 0;
              transition: 0.3s;
              svg {
                width: 20px;
                height: 14px;
                transform: rotate(45deg);
                transition: 0.3s;
              }
            }
          }
          &:hover {
            transform: translate(0, 0);
            .list__item {
              .course__icon {
                border-color: white;
                svg {
                  path {
                    fill: white;
                  }
                }
              }
              .course__info {
                h4,
                p {
                  color: white;
                }
              }
              .course__link {
                background-color: white;
              }
            }
          }
          &:nth-child(3n + 1):hover {
            background: #ffd873;
            box-shadow: 0 0 0 3px black, 16px 16px 0 0 #ea5d2c,
              16px 16px 0 3px black;
            .list__item {
              .course__icon {
                border-color: black;
                svg {
                  path {
                    fill: #121212;
                  }
                }
              }
              .course__info {
                h4,
                p {
                  color: #121212;
                }
              }
              .course__link {
                background-color: white;
                box-shadow: 0 0 0 2px black, 7px 7px 0 0 #337a57,
                  7px 7px 0 2px black;
                svg {
                  transform: rotate(0deg);
                }
              }
            }
          }
          &:nth-child(3n + 2):hover {
            background: #2a6bc5;
            box-shadow: 0 0 0 3px black, 16px 16px 0 0 #337a57,
              16px 16px 0 3px black;
            .course__link {
              box-shadow: 0 0 0 2px black, 7px 7px 0 0 #ea5d2c,
                7px 7px 0 2px black;
              svg {
                transform: rotate(0deg);
              }
            }
          }
          &:nth-child(3n + 3):hover {
            background: #ea5d2c;
            box-shadow: 0 0 0 3px black, 16px 16px 0 0 #2a6bc5,
              16px 16px 0 3px black;
            .course__link {
              background-color: white;
              box-shadow: 0 0 0 2px black, 7px 7px 0 0 #ffd873,
                7px 7px 0 2px black;
              svg {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }
  }
  .team {
    .team__list {
      padding-top: 0;
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
      }
    }
  }
  .address {
    .address__info {
      .address__info-list {
        .address__info-list__item {
          padding: 24px 25px;
        }
        .address__info__title {
          font-size: 16px;
          color: #006da7;
          margin-bottom: 15px;
        }
        .address__info__link {
          flex-grow: 1;
          font-size: 16px;
          text-decoration-line: underline;
          color: #121212;
        }
        .address__info__txt {
          font-size: 24px;
        }
      }
    }
  }
  section.contact {
    background-position: bottom right;
    background-size: auto;
    form {
      width: 100%;
      justify-content: space-between;
      column-gap: 30px;
      flex-direction: row;
      button,
      input {
        margin: 0 !important;
        flex-grow: 1;
      }
    }
  }
  .testimonials {
    .testimonials__heading.container {
      width: 100%;
    }
    .testimonials__slider {
      max-width: fit-content;
      .slider__wrapper {
        .slider__card {
          padding: 40px;
          .card__header {
            .card__img {
              width: 80px;
              height: 80px;
            }

            .card__info {
              .company {
                font-size: 14px;
                margin-bottom: 10px;
              }
              .student-name {
                font-weight: 700;
                font-size: 18px;
              }
            }
          }
          .card__body {
            row-gap: 20px;
            .info__list {
              column-gap: 15px;
              .list__title {
                font-size: 16px;
                line-height: 26px;
              }
              .list__descr {
                column-gap: 18px;
                font-size: 16px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
  .faq {
    .faq__list {
      &__item {
        .question {
          h3 {
            font-size: 18px;
          }
        }
        .answer {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  section {
    .descr {
      width: 50%;
    }
  }
  .promo {
    .promo__content {
      padding-right: 0;
    }
  }
  .courses {
    .courses__list {
      ul {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 36px;
      }
    }
  }
  .partners {
    &__heading {
      margin-bottom: 16px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 36px;
    }
  }
  section.contact {
    background-image: url('../resources/images/contact-bg-desktop.svg');
    background-size: auto;
    background-position: bottom right;
  }
  .address {
    .address__info {
      display: flex;
      align-items: flex-start;
    }
  }
  .team {
    display: none;
  }
  .team-slides {
    display: flex;
  }
  .module {
    .module__list {
      &__heading {
        font-size: 22px;
        font-weight: 700;
      }
      &__item {
        .list__numb {
          font-size: 20px;
        }
        .list__text {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
  .course__price {
    font-weight: 300;
    font-size: 14px;
    .monthly {
      font-size: 20px;
    }
    .total__price {
      font-size: 20px;
    }
  }
}
