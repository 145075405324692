.faq {
	&__heading {
		margin-bottom: 60px;
	}
	.faq__list {
		&__item {
			display: flex;
			flex-direction: column;
			row-gap: 18px;
			background: #f5f5f5;
			transition: 0.5s;
			padding: 18px 18px 0px;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			.question {
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				transition: all 0.5s linear;
				h3 {
					font-size: 14px;
					letter-spacing: -0.05em;
					color: #000000;
					font-weight: 500;
				}
				svg {
					transition: all 0.5s linear;
					width: 18px;
				}
				&.active {
					svg {
						transform: rotate(90deg);
					}
				}
			}
			.answer {
				max-height: 0;
				height: 0;
				font-weight: 400;
				font-size: 12px;
				color: #000000;
				overflow: hidden;
				padding-bottom: 0px;
				opacity: 0;
				transition: all 0.5s linear;
				&.active {
					height: auto;
					max-height: 9999px;
					padding-bottom: 18px;
					opacity: 1;
				}
			}
		}
	}
}
